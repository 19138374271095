<template>
  <div class="setUser">
    <div class="setUserBox">
      <!-- 换头像--手机端 -->
      <section
        class="userImgBox"
        v-if="platform == 'android' && isApp"
        @click="setAvatar"
      >
        <p class="userImgBoxTitle">{{ $t.profilePhoto }}</p>
        <figure class="imgBox">
          <van-image
            class="userImg"
            :src="
              userData.UserHeadPhoto && imgUrlFilter(userData.UserHeadPhoto)
            "
          >
            <template v-slot:error>
              <img
                class="userImg"
                src="~assets/img/userManage/user_logo@2x.png"
              />
            </template>
          </van-image>
        </figure>
      </section>
      <!-- 换头像--网页端/ios -->
      <section class="userImgBox" v-else>
        <van-uploader :after-read="afterRead">
          <p class="userImgBoxTitle">{{ $t.profilePhoto }}</p>
          <figure class="imgBox">
            <van-image
              class="userImg"
              :src="
                userData.UserHeadPhoto && imgUrlFilter(userData.UserHeadPhoto)
              "
            >
              <template v-slot:error>
                <img
                  class="userImg"
                  src="~assets/img/userManage/user_logo@2x.png"
                />
              </template>
            </van-image>
          </figure>
        </van-uploader>
      </section>

      <section class="userImgBox" @click="goEditName">
        <p class="userImgBoxTitle">{{ $t.nickname }}</p>
        <p class="userName" v-if="userData.NickName">{{ userData.NickName }}</p>
        <p class="userName" v-else>{{ $t.userInterpret.editName }}</p>
      </section>
      <section class="userImgBox" @click="onCopy(userData.UserName)">
        <p class="userImgBoxTitle">{{ $t.loginInterpret.userName }}</p>
        <p class="userName">{{ userData.UserName }}</p>
      </section>
      <section class="userImgBox" @click="goInvite(userData.ReferralCode)">
        <p class="userImgBoxTitle">{{ $t.regInfoInterpret.inviteCode }}</p>
        <p class="userName">{{ userData.ReferralCode }}</p>
      </section>
      <section class="userImgBox" @click="gotAuth">
        <p class="userImgBoxTitle">{{ $t.authenticationInfo.checkAuth }}</p>
        <p class="userName">
          <!-- 已认证 -->
          <span
            v-if="shopAuth.IsShopSubmit == 1 && shopAuth.IsShopCheck == 1"
            class="greenBox"
            >{{ $t.authenticationInfo.haveAuth }}</span
          >
          <!-- 未通过 -->
          <span
            v-if="shopAuth.IsShopSubmit == 1 && shopAuth.IsShopCheck == -1"
            class="redBox"
            >{{ $t.authenticationInfo.reAuth }}</span
          >
          <!-- 未认证 -->
          <span
            v-if="shopAuth.IsShopCheck == 0 && shopAuth.IsShopSubmit == 0"
            class="redBox"
            >{{ $t.authenticationInfo.unAuth }}</span
          >
          <!-- 审核中 -->
          <span
            v-if="shopAuth.IsShopCheck == 0 && shopAuth.IsShopSubmit == 1"
            class="yellowBox"
            >{{ $t.authenticationInfo.onAuth }}</span
          >
        </p>
      </section>
      <section class="userImgBox" @click="goEditStore">
        <p class="userImgBoxTitle">{{ $t.meta.myStore }}</p>
        <p class="userName">{{ $t.meta.editMyStore }}</p>
      </section>
      <section class="userImgBox" @click="goAboutUs">
        <p class="userImgBoxTitle">{{ $t.settings.aboutUs }}</p>
        <p class="userName">
          <img
            class="backBoxImg"
            src="~assets/img/userManage/icon_setting_right@2x.png"
          />
        </p>
      </section>
      <section class="userImgBox" @click="goAcountDelete">
        <p class="userImgBoxTitle">{{ $t.settings.accountDelete }}</p>
        <p class="userName">
          <img
            class="backBoxImg"
            src="~assets/img/userManage/icon_setting_right@2x.png"
          />
        </p>
      </section>
      <div class="loginOutBox" @click="loginOut">
        <div class="loginOut">{{ $t.logOut }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Image, Uploader } from "vant";
import { LOG_OUT } from "@/store/mutation-types";
import { mapMutations } from "vuex";
import { SET_AUTH } from "@/store/mutation-types";
import { isAPP, getPlatform } from "@/plugins/environment/type.js";

Vue.use(Image).use(Uploader);
export default {
  name: "setUser",
  components: {},
  data() {
    return {
      isApp: isAPP(),
      platform: getPlatform(),
      IsChecked: false,
      userData: {},
      shopAuth: {},
    };
  },
  created() {
    window.openAlbum = this.openAlbum;
  },
  mounted() {
    let shopAuth = JSON.parse(localStorage.getItem("shopAuth"));
    this.shopAuth = shopAuth;
    this.getUserInfo();
  },
  methods: {
    ...mapMutations([LOG_OUT]),
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    goAboutUs() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "aboutUs",
        name: "AboutUs",
        query: [],
      });
    },
    goAcountDelete() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "user/accountDeletion",
        name: "AccountDeletion",
        query: [],
      });
    },
    goInvite(code) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "invite",
        name: "Invite",
        query: [
          {
            name: "Code",
            value: code,
          },
        ],
      });
    },
    getUserInfo() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      this.$api.user
        .loadUserInfo(param)
        .then((res) => {
          this.userData = res.data;
          this.SET_AUTH({
            ShopId: res.data.ShopId,
            IsShopCheck: res.data.IsShopCheck,
            IsShopSubmit: res.data.IsShopSubmit,
          });
          this.checkAuth(res.data);
          this.getShopInfo(res.data.ShopId, res.data.UserId);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.isfirst = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    getShopInfo(ShopId, UserId) {
      this.$api.countData
        .countData_BIndex({ ShopId: ShopId, UserId: UserId })
        .then((res) => {
          this.shopData = res.data;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.isfirst = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    checkAuth(value) {
      var shopAuth = {};
      shopAuth.IsShopCheck = value.IsShopCheck;
      shopAuth.IsShopSubmit = value.IsShopSubmit;
      localStorage.setItem("shopAuth", null);
      localStorage.setItem("shopAuth", JSON.stringify(shopAuth));
      this.shopAuth = shopAuth;
    },
    gotAuth() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "authentication",
        name: "Authentication",
        query: [],
      });
    },
    goEditName() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "user/editNickname",
        name: "EditNickname",
        query: [],
      });
    },
    goEditStore() {
      if (this.shopAuth.IsShopSubmit == 1 && this.shopAuth.IsShopCheck == 1) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: false,
          path: "editStore",
          name: "EditStore",
          query: [],
        });
      } else {
        this.$commonMethod.checkAuth(this.shopAuth);
      }
    },
    loginOut() {
      this.LOG_OUT();
      this.$commonEnv.commonAction(this.$commonEnv.actionType.userToken, {
        token: "",
      });
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "auth/login",
        name: "Login",
        query: [],
      });
    },
    setAvatar() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.openAlbum, {
        max: 1,
        callback: "openAlbum",
      });
    },
    openAlbum(data) {
      var file = JSON.parse(data);
      this.changAvatar(file.DefaultUrl);
    },
    changAvatar(uri) {
      let param = {
        UserHeadPhoto: uri,
      };
      this.$api.user
        .EditUserInfo(param)
        .then((res) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
            titleMsg: res.message,
            type: "success",
          });
          this.getUserInfo();
        })
        .catch((err) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
            titleMsg: err.message,
            type: "danger",
          });
        });
    },
    onCopy(text) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.clickCopy, {
        text: text,
        alertText: this.$t.copySuccess,
      });
    },
    afterRead(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$commonMethod.showLoading();
      this.$api.system
        .uploadImgFile(formData)
        .then((res) => {
          this.changAvatar(res.data.DefaultUrl);
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    ...mapMutations([SET_AUTH]),
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin publicUser {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 15px 15px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  @include publicFlex;
}
.setUser {
  width: 100%;
  .setUserBox {
    width: 95%;
    margin: 0 auto;
    .userImgBox {
      margin-top: 10px;
      min-height: 70px;
      .van-uploader {
        width: 100%;
        .imgBox {
          float: right;
        }
        .userImgBoxTitle {
          line-height: 50px;
          display: inline-block;
        }
        ::v-deep .van-uploader__input-wrapper {
          width: 100%;
        }
      }
      @include publicUser;
      .userImgBoxTitle {
        @include divPublic;
        font-size: 14px;
        color: #000000;
      }
      .imgBox {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        border: 1px solid #c4e6d5;
        ::v-deep .van-image__error {
          border-radius: 100%;
          position: block;
        }
        ::v-deep .van-image__img {
          border-radius: 100%;
          object-fit: cover;
        }
        .userImg {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          object-fit: cover;
        }
        @include divPublic;
      }
      .userName {
        font-size: 14px;
        color: #000000;
        font-weight: bold;
        margin-right: 10px;
        .backBoxImg{
          width: 15px;
        }
        .greenBox {
          color: #01aaa3;
          font-weight: bold;
        }
        .yellowBox {
          color: orange;
          font-weight: bold;
        }
        .redBox {
          color: red;
          font-weight: bold;
        }
      }
    }
    .loginOutBox {
      width: $publicWidth;
      margin: 8% auto;
      .loginOut {
        position: relative;
        width: 100%;
        margin: 0 auto;
        border-radius: 3rem;
        padding: 0.8rem 0;
        text-align: center;
        background: linear-gradient(left, #71d283, #01aaa3);
        color: #ffffff;
        font-size: 1rem;
        line-height: 1;
      }
    }
  }
}
</style>
